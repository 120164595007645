import { get, post } from "@/application/api";
import { AxiosResponse } from "axios";

export const exportClientDelivery = (
  clientId: number
): Promise<AxiosResponse<Blob>> =>
  get(`client/${clientId}/delivery/concept/export`, {
    responseType: "blob",
  });

export const importClientDelivery = (
  clientId: number,
  file: File
): Promise<AxiosResponse<{ data: { failed: number[] } }>> => {
  const formData = new FormData();
  formData.append("file", file);
  return post(`client/${clientId}/delivery/import`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

import { destroy } from "@/application/api";

export const conceptDeliveryProductDestroy = ({
  clientId,
  deliveryId,
  detInternalIndex,
}: {
  clientId: number;
  deliveryId: number;
  detInternalIndex: number;
}) =>
  destroy(`client/${clientId}/delivery/concept/${deliveryId}`, {
    params: {
      detInternalIndex: detInternalIndex ?? undefined,
    },
  });
